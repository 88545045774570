import { useMemo } from "react";
import { MapProvider } from "react-map-gl";

import { Header, Map, SearchBar, Spinner } from "./components";

import { useAws, useGeolocation, useLoadGoogleAnalitycs, useLoadFacebookPixel, useQueryParams } from "./hooks";
import { AWS_IDENTITY, AWS_REGION } from "./utils/env";

const initialCoords = {
  longitude: -58.4411745,
  latitude: -34.6420621,
};

const App = () => {
  const [client, transformRequest] = useAws(AWS_IDENTITY, AWS_REGION);
  const currentLocationUser = useGeolocation(initialCoords);
  useLoadGoogleAnalitycs('G-KSXL568Z5Q');
  useLoadFacebookPixel('667045987921454');

  const { only_map: onlyMap } = useQueryParams();

  const showExtraComponents = useMemo(
    () => !(!!onlyMap && JSON.parse(onlyMap)),
    [onlyMap]
  );

  return (
    <div>
      {showExtraComponents && <Header />}

      <div
        className={
          showExtraComponents ? "container-root-with-header" : "container-root"
        }
      >
        {transformRequest ? (
          <MapProvider>
            <Map
              transformRequest={transformRequest}
              currentLocationUser={currentLocationUser}
            />

            {showExtraComponents && <SearchBar client={client} />}
          </MapProvider>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default App;
